














import { Component, Prop, Vue } from 'vue-property-decorator'

import ChartBase from '@/components/charts/ChartBase.vue'
import StatusIcon from '@/components/StatusIcon.vue'
import { getPercent } from '@/utils/functions'

@Component({
  components: {
    ChartBase,
    StatusIcon,
  },
})
export default class ChartLesson extends Vue {
  @Prop({ required: true })
  private total!: number

  @Prop({ required: true })
  private value!: number

  @Prop({ default: false })
  private hovered!: boolean

  private get percentTotal () {
    return getPercent(this.total, this.value)
  }

  private get isDone () {
    return this.percentTotal === 100
  }
}
